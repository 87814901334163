<template>
  <div>
    <div
      class="row align-items-center mb-4"
    >
      <div class="col-md-8">
        <b-button
          size="sm"
          variant="margin-reset btn btn-outline-primary btn-sm"
          @click="backToOverview"
        >
          <i class="ion-ios-arrow-back" />
          {{ $t('distributionDetail.button.back') }}
        </b-button>
      </div>
      <cp-head-table
        :title="pageTitle"
        title-icon="ios-git-network"
      />
    </div>
    <div
      v-if="!tableUpdated"
      class="row align-items-center mb-2"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('distributionDetail.loading') }}
      </div>
    </div>
    <filter-boolean-field
      v-if="tableUpdated"
      :filter-field-name="$t('distributionDetail.label.filterFieldName')"
      @applyFilter="applyFilters"
    />
    <cp-table
      ref="cpTable"
      get-data-action="snapshotsRecords/getSnapshotRecordsList"
      :url-params="urlParams"
      :fields="innerTableFields"
      class="mt-4"
      :filters="filters"
      @tableDataUpdated="tableDataUpdated"
    >
      <div
        slot="tableHeader"
        class="card-header d-flex justify-content-end"
      >
        <b-button
          size="sm"
          variant="margin-reset btn btn-outline-primary btn-sm ml-2"
          @click="downloadCsv"
        >
          <i class="ion-ios-download" />
          {{ $t('distributionDetail.button.download') }}
        </b-button>
        <b-button
          size="sm"
          :disabled="distributeCreated"
          variant="btn btn-primary btn-sm ml-2"
          @click="showModal"
        >
          <i class="ion-ios-git-network" />
          {{ $t('distributionDetail.button.generate') }}
        </b-button>
      </div>
      <template
        slot="name"
        slot-scope="{rowData:{item:{investor}}}"
      >
        <div>
          {{ investor.name }}
        </div>
      </template>
      <template
        slot="email"
        slot-scope="{rowData:{item:{investor}}}"
      >
        <div>
          {{ investor.email }}
        </div>
      </template>

      <template
        slot="tokens"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ item.tokens | exponentialToDecimal }}
        </div>
      </template>

      <template
        slot="wallet"
        slot-scope="{rowData:{item:{wallet}}}"
      >
        <div :class="{ 'align-to-middle': !wallet.address }">
          {{ wallet.address | nullToMinus }}
        </div>
      </template>

      <template
        slot="type"
        slot-scope="{rowData:{item}}"
      >
        <div>
          {{ $t(`distributionDetail.status.wallet.${item.type}`) }}
        </div>
      </template>

      <template
        slot="payoutWallet"
        slot-scope="{rowData:{item:{payoutWallet}}}"
      >
        <div>
          {{ payoutWallet.address | nullFilter }}
        </div>
      </template>
    </cp-table>
    <generate-distribution-modal
      ref="generateDistributionModal"
      :distributions-modal-state="distributionsModalState"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { innerTableFields } from '../fields';
import routeNames from '~/utilities/routes';

import generateDistributionModal from '../components/generate-distribution-modal';
import CpHeadTable from '~/components/common/head';
import CpTable from '~/components/shared/cp-table';
import FilterBooleanField from '~/components/common/table/filterField/filterBooleanField';

export default {
  name: 'Distributions',

  metaInfo: {
    title: 'Distributions',
  },

  components: {
    CpTable,
    CpHeadTable,
    generateDistributionModal,
    FilterBooleanField,
  },

  data() {
    return {
      urlParams: {
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
        snapshotId: this.$route.params.idSnapshot,
      },
      snapshotName: this.$route.params.name,
      issuerId: this.$route.params.idIssuer,
      idSnapshot: this.$route.params.idSnapshot,
      innerTableFields,
      tableUpdated: false,
      filters: {},
      distributeCreated: false,
      pageTitle: this.$route.params.name,
    };
  },

  computed: {
    distributionsModalState() {
      return {
        issuerId: this.issuerId,
        id: +this.idSnapshot,
        snapshotName: this.snapshotName,
      };
    },
  },
  watch: {
    filters: {
      handler() {},
      deep: true,
    },
    $route({ params: { tokenId: newTokenId, idIssuer } }, { params: { tokenId: oldTokenId } }) {
      if (newTokenId && newTokenId !== oldTokenId) {
        this.$router.push(routeNames.distributions(idIssuer, newTokenId));
      }
    },
  },

  methods: {
    ...mapActions('snapshotsRecords', ['exportSnapshotsRecords']),

    toViewPage({ id: idHolder }) {
      // TODO make more pretty on distributions refactoring
      this.$router.push(routeNames.viewDetails(this.issuerId, this.$route.params.tokenId, idHolder));
    },
    downloadCsv() {
      const name = this.snapshotName
        ? `distribution_${this.snapshotName.trim().split(' ').join('_')}` : 'distribution_snapshot';
      this.exportSnapshotsRecords({
        issuerId: this.issuerId,
        tokenId: this.$route.params.tokenId,
        snapshotId: this.idSnapshot.toString(),
        name,
      });
    },
    tableDataUpdated() {
      this.tableUpdated = true;
    },
    showModal() {
      this.$refs.generateDistributionModal.$refs.generateDistribution.show();
    },
    backToOverview() {
      const { idIssuer, tokenId } = this.$route.params;
      this.$router.push(routeNames.snapshots(idIssuer, tokenId));
    },
    applyFilters(filter) {
      this.filters = { 'has-payout-wallet': filter };
    },
  },
};
</script>

<style scoped>
  .btn-margin-reset {
    margin-top: 0;
  }
</style>
