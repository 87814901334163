<template>
  <div>
    <b-card>
      <div class="row align-items-end">
        <div class="col-md-4">
          <label class="form-label">
            {{ $t('component.filterBooleanField.label.show', [filterFieldName]) }}
          </label>
          <b-select
            value="all"
            :options="filterOptions"
            @input="applyFilter"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'FilterBooleanField',
  props: {
    filterFieldName: {
      type: String,
      default: 'Field',
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    filterOptions() {
      const defaultOption = {
        true: this.$t('component.filterBooleanField.options.true'),
        false: this.$t('component.filterBooleanField.options.false'),
        all: this.$t('common.label.all'),
      };
      return this.options || defaultOption;
    },
  },
  methods: {
    applyFilter(val) {
      switch (val) {
        case 'true':
          val = true;
          break;
        case 'false':
          val = false;
          break;
        default:
          val = undefined;
      }
      this.$emit('applyFilter', val);
    },
  },
};
</script>
