var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row align-items-center mb-4"},[_c('div',{staticClass:"col-md-8"},[_c('b-button',{attrs:{"size":"sm","variant":"margin-reset btn btn-outline-primary btn-sm"},on:{"click":_vm.backToOverview}},[_c('i',{staticClass:"ion-ios-arrow-back"}),_vm._v(" "+_vm._s(_vm.$t('distributionDetail.button.back'))+" ")])],1),_c('cp-head-table',{attrs:{"title":_vm.pageTitle,"title-icon":"ios-git-network"}})],1),(!_vm.tableUpdated)?_c('div',{staticClass:"row align-items-center mb-2"},[_c('div',{staticClass:"col-md-12 text-center mt-4"},[_vm._v(" "+_vm._s(_vm.$t('distributionDetail.loading'))+" ")])]):_vm._e(),(_vm.tableUpdated)?_c('filter-boolean-field',{attrs:{"filter-field-name":_vm.$t('distributionDetail.label.filterFieldName')},on:{"applyFilter":_vm.applyFilters}}):_vm._e(),_c('cp-table',{ref:"cpTable",staticClass:"mt-4",attrs:{"get-data-action":"snapshotsRecords/getSnapshotRecordsList","url-params":_vm.urlParams,"fields":_vm.innerTableFields,"filters":_vm.filters},on:{"tableDataUpdated":_vm.tableDataUpdated},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var investor = ref.rowData.item.investor;
return [_c('div',[_vm._v(" "+_vm._s(investor.name)+" ")])]}},{key:"email",fn:function(ref){
var investor = ref.rowData.item.investor;
return [_c('div',[_vm._v(" "+_vm._s(investor.email)+" ")])]}},{key:"tokens",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("exponentialToDecimal")(item.tokens))+" ")])]}},{key:"wallet",fn:function(ref){
var wallet = ref.rowData.item.wallet;
return [_c('div',{class:{ 'align-to-middle': !wallet.address }},[_vm._v(" "+_vm._s(_vm._f("nullToMinus")(wallet.address))+" ")])]}},{key:"type",fn:function(ref){
var item = ref.rowData.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(("distributionDetail.status.wallet." + (item.type))))+" ")])]}},{key:"payoutWallet",fn:function(ref){
var payoutWallet = ref.rowData.item.payoutWallet;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("nullFilter")(payoutWallet.address))+" ")])]}}])},[_c('div',{staticClass:"card-header d-flex justify-content-end",attrs:{"slot":"tableHeader"},slot:"tableHeader"},[_c('b-button',{attrs:{"size":"sm","variant":"margin-reset btn btn-outline-primary btn-sm ml-2"},on:{"click":_vm.downloadCsv}},[_c('i',{staticClass:"ion-ios-download"}),_vm._v(" "+_vm._s(_vm.$t('distributionDetail.button.download'))+" ")]),_c('b-button',{attrs:{"size":"sm","disabled":_vm.distributeCreated,"variant":"btn btn-primary btn-sm ml-2"},on:{"click":_vm.showModal}},[_c('i',{staticClass:"ion-ios-git-network"}),_vm._v(" "+_vm._s(_vm.$t('distributionDetail.button.generate'))+" ")])],1)]),_c('generate-distribution-modal',{ref:"generateDistributionModal",attrs:{"distributions-modal-state":_vm.distributionsModalState}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }